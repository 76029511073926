import { capitalize, pluralize, singularize } from "../i18n/inflector"

function groupBy(this: Array<any>, f: Function) {
    return this.reduce((acc, x) => {
        acc[f(x)] = acc[f(x)] || []
        acc[f(x)].push(x)

        return acc
    }, {})
}

function capitalizeFn(this: string) {
    return capitalize(this)
}

function pluralizeFn(this: string) {
    return pluralize(this)
}

function singularizeFn(this: string) {
    return singularize(this)
}

declare global {
    interface String {
        capitalize: (this: string) => any
        pluralize: (this: string) => any
        singularize: (this: string) => any
    }

    interface Array<T> {
        groupBy: typeof groupBy
        flat<U>(this: U[][][][][][][][], depth: 7): U[]
        flat<U>(this: U[][][][][][][], depth: 6): U[]
        flat<U>(this: U[][][][][][], depth: 5): U[]
        flat<U>(this: U[][][][][], depth: 4): U[]
        flat<U>(this: U[][][][], depth: 3): U[]
        flat<U>(this: U[][][], depth: 2): U[]
        flat<U>(this: U[][], depth?: 1): U[]
        flat<U>(this: U[], depth: 0): U[]
        flat<U>(depth?: number): any[]
        uniq(): Array<T>
    }
}

String.prototype.capitalize = capitalizeFn
String.prototype.pluralize = pluralizeFn
String.prototype.singularize = singularizeFn

Object.defineProperty(Array.prototype, "groupBy", { value: groupBy, enumerable: false })
