import * as Inflector from "inflected"

const rules: any = {
    plural: [
        [/$/, "s"],
        [/([^aeéiou])$/i, "$1es"],
        [/([aeiou]s)$/i, "$1"],
        [/z$/i, "ces"],
        [/á([sn])$/i, "a$1es"],
        [/é([sn])$/i, "e$1es"],
        [/í([sn])$/i, "i$1es"],
        [/ó([sn])$/i, "o$1es"],
        [/ú([sn])$/i, "u$1es"]
    ],
    singular: [
        [/s$/, ""],
        [/es$/, ""],
        [/([sfj]e)s$/, "$1"],
        [/ces$/, "z"]
    ],
    irregular: [
        ["multiple", "multiples"],
        ["pointcut", "pointcuts"],
        ["el", "los"],
        ["pais", "paises"],
        ["chile", "chile"],
        ["colombia", "colombia"],
        ["tec", "tec"],
        ["streaming", "streaming"],
        ["referrer", "referrers"],
        ["banner", "banners"],
        ["click", "clicks"],
        ["campaign", "campaigns"],
        ["data", "data"],
        ["reporte", "reportes"],
        ["sede", "sedes"],
        ["nivel", "niveles"],
        ["alias", "aliases"]
    ]
}

Inflector.inflections("es", function (inflect) {
    rules.plural.forEach(rule => {
        inflect.plural(rule[0], rule[1])
    })

    rules.singular.forEach(rule => {
        inflect.singular(rule[0], rule[1])
    })

    rules.irregular.forEach(rule => {
        inflect.irregular(rule[0], rule[1])
    })
})

export function pluralize(word: string, locale: string = "es") {
    return Inflector.pluralize(word, locale)
}

export function singularize(word: string, locale: string = "es") {
    return Inflector.singularize(word, locale)
}

export function capitalize(word: string, locale: string = "es") {
    return Inflector.capitalize(word, locale)
}

export function titleize(word: string, locale: string = "es") {
    return Inflector.titleize(word, locale)
}
